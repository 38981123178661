@import "./app/shared/styles/badge";

html{
  background-color: transparent !important;
}
body {
  margin-top: 0 !important;
  &.camera-preview {
    background: transparent !important;
  }
  #loader {
    padding-top: var(--sat);
  }
  #custom_bg {
    display: none;
  }
  #home_logo_shadow {
    display: none;
  }
}

.btn {
  min-height: 50px !important;
}
.back_btn {
  height: 50px !important;
  width: 50px !important;
}
.view_deco_image {
  img.deco_image_m_lg {
    max-height: 140px!important;
    margin: 36px 0 28px !important;
    @media (max-height: 700px) {
      margin: 24px 0 20px !important;
      max-height: 110px!important;
    }
  }
}
.slideElement {
  padding: 4px 46px 4px 4px !important;
  min-height: 40px !important;
  &.textRight{
    padding: 4px 10px 4px 4px !important;
  }
}

.input_wrapper input, .input_wrapper select {
  height: 40px !important;
  padding: 8px 50px 8px 18px !important;
}


.OCRB {
  font-family: "OCRB", monospace !important;
}

@font-face {
  font-family: "OCRB";
  src: url("assets/fonts/OCRB.ttf") format("truetype");
}

.popup-image{
  width: 100%;
}

#appVersion {
  font-size: 0.7rem !important;
  opacity: 0.4;
  left: unset !important;
  right: 12%;
  text-align: center;
}
