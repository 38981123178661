@import 'node_modules/ngx-satoris/lib/shared/styles/variables';

.scannedBadge {
  padding: 30px 10px;
  border-radius: 50px;
  background: linear-gradient(100deg, #2F6A739C, #2F6A73C9, #2F6A73BF, #2F6A73BA, #2F6A73D9, #2F6A73C4, #2F6A73E8);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-shadow: 2px 3px 10px rgba(0,0,0,0.2);
  max-width: 500px;
  margin: 0 auto;

  &_network {
    position: absolute;
    top: 30px;
    right: 30px;
    font-size: 1.6rem;
    color: #F93D42E5;
  }

  &_img {
    width: 120px;
    height: 120px;
    border-radius: 100%;
    background: #FFD242;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    box-shadow: 2px 4px 7px rgba(0,0,0,0.3), inset 0 0 30px rgba(255,255,255,0.7);
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &_title {
    font-size: 6vw;
    color: var(--clr-lt);
    text-align: center;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
    @include minSize(sm) {
      font-size: 2rem;
    }
  }

  &_name {
    font-weight: 500;
  }

  &_place {
    font-weight: 500;
  }

  &_perms {
    padding: 18px;
    border-radius: 30px;
    background: rgba(0,0,0,0.3);
    box-shadow: inset 0 0 10px rgba(0,0,0,0.3);
    width: 100%;
    color: var(--clr-lt);

    &_title {
      opacity: 0.7;
      font-size: 0.8rem;
      text-align: right;
      width: 100%;
      display: block;
    }

    &_claims {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 4px;
    }

    &_snack {
      padding: 4px 8px;
      background: #1D2B2D;
      border-radius: 6px;

      span {
        font-size: 0.9rem;
        display: flex;
        align-items: center;
        gap: 6px;

        &:before {
          content: '';
          display: block;
          width: 5px;
          height: 5px;
          background: #94DB77;
          border-radius: 100%;
        }
      }
    }

    p {
      text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
      margin: 10px 0;
    }
  }

  &_footer {
    transform: translateY(7px);
    color: rgba(255, 255, 255, 0.7);
    height: 0;
    font-size: 0.8rem;
  }
}
